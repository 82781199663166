<!--
 * @Descripttion:
 * @version:
 * @Author: 郭梦霞
 * @Date: 2023-11-19 13:36:07
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-06-11 11:41:26
-->
<template>
  <div>
    <el-card>
      <div class="return-title">
        <div class="back-btn" @click="_backBtn"><i class="el-icon-back mr10"></i>返回</div>
        <span class="division">|</span>
        <div class="back-title">新增考试</div>
      </div>
      <div class="form-style">
        <el-form :model="arrangeForm" ref="arrangeForm" class="form-content" :rules="arrangeFormRules" label-width="120px">
          <el-form-item label="考试名称" prop="examName">
            <el-input style="width: 70%;"  v-model="arrangeForm.examName" placeholder="请输入考试名称" clearable></el-input>
          </el-form-item>
          <el-form-item label="试卷名称" prop="testPaperInfoId">
            <el-select v-model="arrangeForm.testPaperInfoId" style="width:55%;" class="paper-name" filterable>
              <el-option
                v-for="item in paperList"
                :key="item.id"
                :value="item.id"
                :label="item.name"
                @click.native="_selectPaperName(item)"
              ></el-option>
            </el-select>
            <el-button class="select-btn" type="text" @click.native="_createPaper" style="width:15%;height:32px;">创建试卷</el-button>
          </el-form-item>
          <el-form-item label="答题时长" class="time" prop="duration">
            <el-input type="number" style="width: 70%;" v-model="arrangeForm.duration" placeholder="请输入答题时长" >
              <i slot="suffix" style="font-style:normal;color:#1A1A1A;margin-right:6px;">分钟</i>
            </el-input>
          </el-form-item>
          <el-form-item label="考试时间">
            <el-date-picker
              style="width: 70%;"
              v-model="examTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd HH:mm"
              value-format="yyyy-MM-dd HH:mm"
              :default-time="['00:00:00', '23:59:59']"
              @change="_selectExamTime">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="考试通过分数线" class="time">
            <el-input type="number" style="width: 70%;" v-model="arrangeForm.gradeThreshold" @input="_inputPaperMark(arrangeForm.gradeThreshold)" placeholder="请输入考试通过分数线">
              <i slot="suffix" style="font-style:normal;color:#1A1A1A;">分</i>
            </el-input>
            <span style="font-size: 12px; color:#999999; margin-left:16px;">试卷总分为{{paperTotalMark}}分</span>
          </el-form-item>
          <el-form-item label="考试人员" prop="examUser">
            <div class="select-box"  style="width: 70%;">
              <div class="select-box-title">
                <span>已选员工（{{arrangeForm.examUser.length}}）</span>
              </div>
              <div class="select-box-info">
                <el-tag
                  v-for="(tag,index) in arrangeForm.examUser"
                  :key="index"
                  closable
                  :disable-transitions="false"
                  @close="_examUserTagsClose(tag,index)">
                  {{tag.employeeName}}
                </el-tag>
                <el-tag effect="dark" @click="_selectExamUser"><i class="el-icon-plus"></i>人员</el-tag>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="考试介绍">
            <el-input type="textarea" v-model="arrangeForm.introduce" placeholder="请输入考试介绍" style="width:70%;" autosize></el-input>
          </el-form-item>
          <el-form-item label="答案解析设置" prop="analysisType">
            <el-radio-group  v-model="arrangeForm.analysisType"  style="width: 70%;">
              <el-radio :label="2">不允许查看</el-radio>
              <el-radio :label="1">考试完成后</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="允许查看试卷">
            <el-radio-group  v-model="arrangeForm.examineFlag"  style="width: 70%;">
              <el-radio :label="1">考试完成后</el-radio>
              <el-radio :label="2">批阅完成后</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="阅卷人" prop="examerType">
            <el-radio-group  v-model="arrangeForm.examerType" style="width: 70%;" @input="_selectExamerType">
              <el-radio :label="1">考试创建人</el-radio>
              <el-radio :label="2">指定人员</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="" prop="examiners" v-if="arrangeForm.examerType==2">
            <el-select
              style="width:70%;"
              filterable
              value-key="examinerId"
              multiple
              v-model="arrangeForm.examiners"
              placeholder="请选择">
              <el-option
                v-for="item in markUserList"
                :key="item.examinerId"
                :label="item.examiner"
                :value="item"
                @click.native="_selectMarkUser(item)"/>
            </el-select>
          </el-form-item>
          <div class="el-form-item el-form-item--small">
            <label for="reminderMinutes" class="el-form-item__label" style="width: 120px;">
              <el-checkbox v-model="reminderMinutesChecked"></el-checkbox>
            </label>
            <div class="el-form-item__content" style="margin-left: 120px;">
              <span>考试前
                <el-select
                  :disabled="!reminderMinutesChecked"
                  style="width:65px;"
                  v-model="arrangeForm.reminderMinutes"
                  filterable
                  allow-create
                  placeholder="请选择">
                  <el-option
                    v-for="item in examRemindTimeArr"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
                分钟提醒</span>
            </div>
          </div>
          <el-form-item label="显示考试成绩" prop="markShowFlag">
            <el-radio-group  v-model="arrangeForm.markShowFlag"  style="width: 70%;">
              <el-radio :label="1">批阅完成后</el-radio>
              <el-radio :label="2">考试完成显示</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="是否允许切屏" prop="cutScreenFlag">
            <el-radio-group  v-model="arrangeForm.cutScreenFlag"  style="width: 70%;" @input="_changeCutScreenRadio">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="允许切屏次数" v-if="arrangeForm.cutScreenFlag==1">
            <el-input style="width: 70%;"  v-model="arrangeForm.cutScreenNum" placeholder="请输入允许切屏次数" clearable></el-input>
          </el-form-item>
          <el-form-item label="可见范围" prop="useType">
            <el-radio-group v-model="arrangeForm.useType">
              <el-radio :label="2">可见范围</el-radio>
              <el-radio :label="1">全部</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="" prop="useDeptId" v-if="arrangeForm.useType==2">
            <el-select filterable value-key="id" clearable v-model="arrangeForm.useDeptId" placeholder="请选择" style="width: 70%;">
              <el-option
                v-for="item in visibleDeptList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                @click.native="_selectDept(item)"
              />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="footer-btn">
        <el-button @click="_backBtn">取消</el-button>
        <el-button type="primary" @click="_saveExamArrange" :loading="currentLoading">保存</el-button>
      </div>
    </el-card>
    <!-- 选择人员 -->
    <SelectPersonnel
      v-if="personnelShow"
      :personnelShow="personnelShow"
      :pageTypeValue="pageTypeValue"
      :readio="isPersonRadio?'readio':undefined"
      @submitHandle="_submitHandle"
      type="lecturerSetting"
      title="选择人员"
    />
  </div>
</template>
<script>
import SelectPersonnel from '@/components/selectPersonnel/index'
import { getPaperListApi, getExamArrangeAddApi, getHistoryMarkUserApi } from '@/api/examApi'
import { getManageDeptListApi, getUserListApi } from '@/api/api'
import { getCurrentTime } from '@/utils/util'
// import { openFullScreenLoading } from '@/utils/util'

export default {
  components: { SelectPersonnel },
  data () {
    return {
      refreshRichTextEditorKey: new Date().getTime(),
      visibleDeptList: [], // 可见部门列表
      addExamDialog: false,
      reminderMinutesChecked: false,
      arrangeForm: {
        examName: '', // 考试名称
        testPaperInfoId: null, // 试卷名称id
        testPaperName: null, // 试卷名称Name
        sumQuestionNum: '', // 试卷总题数
        sumScore: '', // 试卷总分
        duration: '', // 答题时长
        gradeThreshold: '', // 考试通过分数线
        tester: '', // 考试人员
        examUser: [], // 考试人员
        startTime: '', // 考试开始时间
        endTime: '', // 开始结束时间
        introduce: '', // 考试介绍
        analysisType: '', // 答案设置解析
        examineFlag: '', // 允许查看试卷
        examerType: '', // 阅卷人设置
        examerId: '', // 审阅人员id
        examer: '', // 审阅人员name
        examiners: [], // 审阅人员
        reminderMinutes: 5, // 考前提醒多少分钟数,默认为5
        useType: null, // 可见范围
        useDeptId: '', // 可见范围--部门id
        useDeptName: '', // 可见范围--部门name
        cutScreenFlag: null, // 是否允许切屏
        cutScreenNum: null,
        markShowFlag: null // 显示考试成绩
      },
      pageTypeValue: 'arrangeExamPage',
      isPersonRadio: false,
      paperTotalMark: 100, // 试卷总分
      arrangeFormRules: {
        examName: [{ required: true, message: '请输入考试名称', trigger: 'blur' }],
        testPaperInfoId: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
        examUser: [{ required: true, message: '请选择考试人员', trigger: 'blur' }],
        startTime: [{ required: true, message: '请选择考试时间', trigger: ['blur', 'change'] }],
        examerType: [{ required: true, message: '请选择阅卷人', trigger: ['blur', 'change'] }],
        examiners: [{ required: true, message: '请选择阅卷人', trigger: ['blur', 'change'] }],
        useType: [
          { required: true, message: '请选择可见范围', trigger: ['change', 'blur'] }
        ],
        useDeptId: [
          { required: true, message: '请选择部门', trigger: ['change', 'blur'] }
        ],
        cutScreenFlag: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
        markShowFlag: [{ required: true, message: '请选择', trigger: ['blur', 'change'] }],
        duration: [
          { required: true, message: '请输入答题时长', trigger: 'change' }
        ]
      },
      paperList: [], // 试卷列表
      examTime: '',
      selectUserType: '',
      markUser: {},
      personnelShow: false,
      markUserList: [],
      currentLoading: false,
      examRemindTimeArr: [{
        value: 5,
        label: 5
      }, {
        value: 10,
        label: 10
      }, {
        value: 15,
        label: 15
      }, {
        value: 20,
        label: 20
      }, {
        value: 30,
        label: 30
      }]
    }
  },
  created () {
    this._getPaperList()// 试卷列表
    this._getAllUserList()// 获取组织架构人员
    this._getManageDeptList()// 可见部门
    if (this.$route.query.id && this.$route.query.name && this.$route.query.mark) {
      const params = {
        id: Number(this.$route.query.id),
        name: this.$route.query.name,
        mark: this.$route.query.mark
      }
      this._selectPaperName(params)
    }
  },
  methods: {
    // 获取试卷列表
    _getPaperList () { // 查询已发布的试卷
      getPaperListApi({ status: 1, page: 1, pageSize: 9999, testPaperGroupId: 0 }).then((res) => {
        if (res.code === 200) {
          this.paperList = res.object.results
          console.log(this.paperList, 'this.paperList')
        }
      })
    },
    // 选择试卷
    _selectPaperName (item) {
      this.arrangeForm.gradeThreshold = null
      this.arrangeForm.testPaperInfoId = item.id
      this.arrangeForm.testPaperName = item.name
      this.paperTotalMark = item.mark
    },
    // 考试通过分数线--打分
    _inputPaperMark (mark) {
      // 限制考试通过分数线最大值和最小值-start
      if (mark !== undefined && mark !== null && mark !== '') {
        if (Number(mark) > Number(this.paperTotalMark)) {
          this.$set(this.arrangeForm, 'gradeThreshold', Number(this.paperTotalMark))
        }
        if (Number(mark) < 0) {
          this.$set(this.arrangeForm, 'gradeThreshold', 0)
        }
      }
    },
    // 限制考试通过分数线最大值和最小值-end
    // 选择考试人员
    _selectExamUser () {
      this.selectUserType = 'exam-user'
      this.personnelShow = true
    },
    // 点击删除已选择考试人员
    _examUserTagsClose (tag, index) {
      this.arrangeForm.examUser.splice(index, 1)
    },
    // 选择阅卷人
    _selectMarkingUser () {
      this.selectUserType = 'mark-user'
      this.isPersonRadio = true
      this.personnelShow = true
    },
    // 点击删除已选择批阅人员
    _markUserTagsClose (tag, index) {
      this.markUser.splice(index, 1)
    },
    // 选择人员
    _submitHandle (arr) {
      const newArr = arr.map((item) => {
        return {
          employeeId: item.userId, // 人员id
          employeeName: item.name, // 人员name
          deptId: item.parentId,
          deptName: item.parentName,
          id: undefined
        }
      })
      if (this.selectUserType === 'exam-user') {
        // 考试人员--多选
        this.arrangeForm.examUser.push.apply(this.arrangeForm.examUser, newArr)
        this.arrangeForm.examUser = this._deduplicate(this.arrangeForm.examUser)
      }
      this.personnelShow = false
    },
    // 获取组织架构人员
    _getAllUserList () {
      getUserListApi({ pageSize: 0, userPostStatus: 4 }).then((res) => {
        this.markUserList = JSON.parse(JSON.stringify(res.data.data))
        this.markUserList = this.markUserList.map((item) => {
          return {
            examinerId: item.userId,
            examiner: item.userName
          }
        })
      })
    },
    // 选择阅卷人
    _selectMarkUser (item) {
      this.arrangeForm.examerId = item.examinerId// 审阅人员id
      this.arrangeForm.examer = item.examiner// 审阅人员name
    },
    // 选择考试时间
    _selectExamTime (a) {
      if (a) {
        const examStartTime = new Date(a[0]).getTime()// 考试设置的开始时间
        const examEndTime = new Date(a[1]).getTime()// 考试设置的截止时间
        const currentTime = new Date(getCurrentTime()).getTime()// 当前时间
        if (examStartTime < currentTime || examEndTime < currentTime) {
          this.examTime = ''
          return this.$message.error('考试开始和结束时间不能小于当前时间！')
        }
        this.arrangeForm.startTime = a[0]
        this.arrangeForm.endTime = a[1]
      } else {
        this.arrangeForm.startTime = ''
        this.arrangeForm.endTime = ''
      }
    },
    // 选择阅卷人类型
    _selectExamerType (e) {
      if (e === 1) {
        this.arrangeForm.examerId = this.$store.state.userList.userId// 审阅人员id
        this.arrangeForm.examer = this.$store.state.userList.userName// 审阅人员name
      } else {
        this.arrangeForm.examerId = ''// 审阅人员id
        this.arrangeForm.examer = ''// 审阅人员name
        this.arrangeForm.examiners = []
        // 获取上次历史数据---进行回显
        getHistoryMarkUserApi().then((res) => {
          if (res.code === 200) {
            console.log(res.data, 'res.data')
            this.arrangeForm.examiners = res.data
          }
        })
      }
    },
    // 选择是否允许切屏
    _changeCutScreenRadio (e) {
      if (e === 1) {
        this.arrangeForm.cutScreenNum = 3
      }
    },
    // 保存考试安排
    _saveExamArrange () {
      this.$refs.arrangeForm.validate((valid) => {
        if (valid) {
          if (this.arrangeForm.examerType === 1) { // 当阅卷人为考试创建人
            this.arrangeForm.examiners = [{
              examinerId: this.$store.state.userList.userId,
              examiner: this.$store.state.userList.userName
            }]
          }
          const params = {
            ...this.arrangeForm,
            analysisType: this.arrangeForm.analysisType ? this.arrangeForm.analysisType : 2, // 默认传答案解析设置--不允许查看
            examineFlag: this.arrangeForm.examineFlag ? this.arrangeForm.examineFlag : 1, // 默认允许查看试卷--不允许查看
            reminderMinutes: this.reminderMinutesChecked ? this.arrangeForm.reminderMinutes : '',
            tester: JSON.stringify(this.arrangeForm.examUser)
          }
          if (params.duration) {
            const examStartTime = new Date(params.startTime).getTime()// 考试设置的开始时间
            const examEndTime = new Date(params.endTime).getTime()// 考试设置的截止时间
            const differentTime = examEndTime - examStartTime
            const durationTime = Number(params.duration) * 60 * 1000
            if (differentTime < durationTime) {
              return this.$message.error('考试时间设置的区间不可小于考试时长！')
            }
          }
          this.currentLoading = true
          getExamArrangeAddApi({ ...params }).then((res) => {
            if (res.code === 200) {
              this.$message.success('保存成功！')
              this.currentLoading = false
              this.$router.push({
                path: '/examArrange'
              })
            } else {
              this.currentLoading = false
            }
          })
        }
      })
    },
    // 可见部门列表（可见范围）
    _getManageDeptList () {
      getManageDeptListApi().then((res) => {
        if (res.code === 200) {
          this.visibleDeptList = res.data
        }
      })
    },
    // 选择可见范围-部门
    _selectDept (item) {
      this.arrangeForm.useDeptId = item.id
      this.arrangeForm.useDeptName = item.name
    },
    _backBtn () {
      if (this.$route.query.id) {
        this.$router.push({
          path: '/examPaperManage'
        })
      } else {
        this.$router.push({
          path: '/examArrange'
        })
      }
    },
    // 数组对象去重
    _deduplicate (arr) {
      const map = new Map()
      for (const item of arr) {
        if (!map.has(item.employeeId)) {
          map.set(item.employeeId, item)
        }
      }
      return [...map.values()]
    },

    // 创建试卷
    _createPaper () {
      this.$router.push({
        path: '/examPaperManage'
      })
    },
    // 获取到富文本编辑器的内容
    _getRichTextHtml (editDataHtml) {
      // 获取最新的html数据
      // console.log(editDataHtml, 'hgfhgfhhj')
      this.arrangeForm.introduce = editDataHtml
    }
  }
}
</script>
<style lang="scss" scoped>
// 头部返回标题样式
.return-title{
  display: flex;
  align-items: center;
  .back-btn{
    font-size: 14px;
    color: #303133;
  }
  .division{
    color: #F3F3F3;
    width: 1px;
    background-color: #F3F3F3;
    border-radius: 2px;
    margin: 0px 16px;
  }
  .back-title{
    font-size: 16px;
    color: #333333;
  }
}
// 表单样式
.form-style{
  height: calc(100vh - 230px);
  overflow-y: scroll;
  .form-content{
  width: 60%;
  margin: 24px auto;
    /deep/.el-form-item {
      margin-bottom: 18px !important;
    }
    .quill-editor{
      width:70%!important;
      /deep/.ql-toolbar.ql-snow{
        padding: 0px!important;
      }
    }
    .select-box{
      padding:16px 12px;
      background-color: #F6F7F9;
      box-sizing: border-box;
      border-radius: 4px;
      .select-box-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 20px;
        line-height:20px;
        margin-bottom: 12px;
        span {
          font-size: 14px;
          font-weight: 500;
          color: rgba(0,0,0,0.85);
        }
      }
    }
}
}

.footer-btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 8px 0;
  box-shadow: 0px -1px 1px 0px rgba(0,0,0,0.04);
}

.group-style{
  /deep/.el-input--small{
    width: 84%;
  }
}
.add-group{
    display: flex;
    justify-content: center;
    margin-top: 12px;
    .add-group-btn{
      width: 102px;
      font-size: 14px;
    }
  }
  .add-group-name{
    display: flex;
    margin-bottom: 10px;
    .iconfont{
      margin-left: 10px;
    }
  }
  .paper-name{
    /deep/.el-input--small .el-input__inner{
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
  .select-btn {
    border: 1px solid #dcdcdc !important;
    border-left: none!important;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
  //
  /deep/.editor-inner{
    height: 100px!important;
  }
  /deep/.el-tag--small{
    min-width: 74px;
    height: 28px;
    line-height: 25px;
    margin-right: 12px;
    font-size: 14px;
    text-align: center;
  }
.exam-remind{
  width:6%;
  margin:0px 2px;
  /deep/.el-input__inner{
    padding: 0px 4px!important;
  }
}
</style>
